import _ from "lodash";

import Entity from "../Entity";
import * as actions from "./actions";
import * as contentActions from "../content/actions";
import * as spotlightActions from "../spotlights/actions";
import * as scheduleActions from "../schedules/actions";
import * as upshowNowSchedulesActions from "../upshowNowSchedules/actions";
import * as upshowNowChannelsActions from "../upshowNowChannels/actions";
import * as plutoSchedulesActions from "../plutoSchedules/actions";
import * as plutoChannelsActions from "../plutoChannels/actions";
import * as jukinFeedsActions from "../jukinFeeds/actions";
import * as jukinSchedulesActions from "../jukinSchedules/actions";
import * as triviaSchedulesActions from "../triviaSchedules/actions";
import * as scriptSchedulesActions from "../scriptSchedules/actions";
import * as spotlightBackgroundsActions from "../spotlightBackgrounds/actions";
import * as spotlightAssetsActions from "../spotlightAssets/actions";
import * as uiScriptsActions from "../uiScripts/actions";
import { buildUrl } from "../../../../helpers/organizationPath";
import { buildFeatureAccesor } from "../../../../helpers/featureAccesor";

const defaultTimezoneList = [{timezone: 'America/Chicago'}];

class Organization extends Entity {

    constructor() {
        super('organizations');

        this.define({
            children: [this],
        });

        this.initialState = {
            ...this.initialState,
            currentOrganizationPath: [],
            rootOrganizations: [],
            isFetchingRootOrganizations: false,
            errorFetchingRootOrganizations: null,
            isSwitchingOrganization: false,
            errorSwitchingOrganization: null,
        };
    }

    reducer(state = this.initialState, action) {

        switch (action.type) {

            case actions.REDIRECT_TO_RELATIVE_URL: {

                const {redirectToRelativeUrl} = action.payload;
                return {
                    ...state,
                    redirectToRelativeUrl: redirectToRelativeUrl
                };
            }

            case actions.REDIRECT_TO_RELATIVE_URL_SUCCESS: {

                return {
                    ...state,
                    redirectToRelativeUrl: null
                };
            }

            case actions.GET_ROOT_ORGANIZATIONS: {
                return {
                    ...state,
                    isFetchingRootOrganizations: true,
                };
            }

            case actions.GET_ROOT_ORGANIZATIONS_SUCCESS: {
                const {rootOrganizationsIds} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    isFetchingRootOrganizations: false,
                    errorFetchingRootOrganizations: null,
                    rootOrganizations: [...rootOrganizationsIds],
                };
            }

            case actions.GET_ROOT_ORGANIZATIONS_FAILURE: {
                const {error} = action.payload;

                return {
                    ...state,
                    isFetchingRootOrganizations: false,
                    errorFetchingRootOrganizations: error,
                };
            }

            case actions.SWITCH_ORGANIZATION: {
                return {
                    ...state,
                    isSwitchingOrganization: true,
                };
            }

            case actions.SWITCH_ORGANIZATION_SUCCESS: {
                return {
                    ...state,
                    isSwitchingOrganization: false,
                    errorSwitchingOrganization: null,

                    currentOrganizationPath: action.payload.organizationPath,
                };
            }

            case actions.SWITCH_ORGANIZATION_FAILURE: {
                return {
                    ...state,
                    isSwitchingOrganization: false,
                    errorSwitchingOrganization: action.payload.error,
                };
            }

            case actions.GET_ORGANIZATION_TIMEZONES_SUCCESS: {
                return {
                    ...state,
                    timezones: action.payload.timezones,
                    errorGetOrganizationTimezones: null
                };
            }

            case actions.GET_ORGANIZATION_TIMEZONES_FAILURE: {
                return {
                    ...state,
                    timezones: [],
                    errorGetOrganizationTimezones: action.payload.error,
                };
            }

            case contentActions.GET_CONTENT: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingContent: true,
                            errorFetchingContent: null,
                        }
                    },
                };
            }

            case contentActions.GET_CONTENT_SUCCESS: {
                const {organizationId, contentIds} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            content: [...contentIds],
                        }
                    },
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingContent: false,
                            errorFetchingContent: null,
                        }
                    },
                };
            }

            case contentActions.GET_CONTENT_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingContent: false,
                            errorFetchingContent: error,
                        }
                    },
                };
            }

            case spotlightActions.GET_SPOTLIGHTS: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingSpotlights: true,
                            errorFetchingSpotlights: null,
                        }
                    },
                };
            }

            case spotlightActions.GET_SPOTLIGHTS_SUCCESS: {
                const {organizationId, spotlightIds} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...newState.byId,
                        [organizationId]: {
                            ...newState.byId[organizationId],
                            spotlights: [...spotlightIds],
                        }
                    },
                    metadataById: {
                        ...newState.metadataById,
                        [organizationId]: {
                            ...newState.metadataById[organizationId],
                            isFetchingSpotlights: false,
                            errorFetchingSpotlights: null,
                        }
                    },
                };
            }

            case spotlightActions.GET_SPOTLIGHTS_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingSpotlights: false,
                            errorFetchingSpotlights: error,
                        }
                    },
                };
            }

            case spotlightActions.GET_SPOTLIGHT_SUCCESS: {
                const {organizationId, spotlightId} = action.payload;

                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...newState.byId,
                        [organizationId]: {
                            ...newState.byId[organizationId],
                            spotlights: _.union(
                                _.get(newState, `byId.${organizationId}.spotlights`, []),
                                [spotlightId]
                            ),
                        }
                    }
                }
            }

            case spotlightActions.DELETE_SPOTLIGHT_SUCCESS: {
                const {organizationId, spotlightId} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            spotlights: _.difference(
                                _.get(state, `byId.${organizationId}.spotlights`, []),
                                [spotlightId],
                            ),
                        }
                    }
                };
            }

            case spotlightActions.CREATE_SPOTLIGHT: {
                const {organizationId} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isCreatingSpotlight: true,
                            errorCreatingSpotlights: null,
                        }
                    },
                };
            }

            case spotlightActions.CREATE_SPOTLIGHT_SUCCESS: {
                const {organizationId} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            spotlights: _.union(
                                _.get(state, `byId.${organizationId}.spotlights`, [])
                            ),
                        }
                    },
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isCreatingSpotlight: false,
                            errorCreatingSpotlights: null,
                        }
                    },
                };
            }

            case spotlightActions.CREATE_SPOTLIGHT_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isCreatingSpotlight: false,
                            errorCreatingSpotlights: error,
                        }
                    },
                };
            }

            case spotlightBackgroundsActions.GET_SPOTLIGHT_BACKGROUNDS: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingSpotlightBackgrounds: true,
                            errorFetchingSpotlightBackgrounds: null,
                        }
                    },
                };
            }

            case spotlightBackgroundsActions.GET_SPOTLIGHT_BACKGROUNDS_SUCCESS: {
                const {organizationId, spotlightBackgroundsIds} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            spotlightBackgrounds: [...spotlightBackgroundsIds],
                        }
                    },
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingSpotlightBackgrounds: false,
                            errorFetchingSpotlightBackgrounds: null,
                        }
                    },
                };
            }

            case spotlightBackgroundsActions.GET_SPOTLIGHT_BACKGROUNDS_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingSpotlightBackgrounds: false,
                            errorFetchingSpotlightBackgrounds: error,
                        }
                    },
                };
            }

            case spotlightAssetsActions.GET_SPOTLIGHT_ASSETS: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingSpotlightAssets: true,
                            errorFetchingSpotlightAssets: null,
                        }
                    },
                };
            }

            case spotlightAssetsActions.GET_SPOTLIGHT_ASSETS_SUCCESS: {
                const {organizationId, spotlightAssetsIds} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            spotlightAssets: [...spotlightAssetsIds],
                        }
                    },
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingSpotlightAssets: false,
                            errorFetchingSpotlightAssets: null,
                        }
                    },
                };
            }

            case spotlightAssetsActions.GET_SPOTLIGHT_ASSETS_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingSpotlightAssets: false,
                            errorFetchingSpotlightAssets: error,
                        }
                    },
                };
            }

            case spotlightAssetsActions.GET_SPOTLIGHT_ASSET_SUCCESS: {
                const {organizationId, spotlightAssetId} = action.payload;
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            spotlightAssets: _.union(
                                _.get(state, `byId.${organizationId}.spotlightAssets`, []),
                                [spotlightAssetId]
                            ),
                        }
                    }
                }
            }
            case spotlightAssetsActions.CREATE_SPOTLIGHT_ASSET: {
                const {organizationId} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isCreatingSpotlightAsset: true,
                            errorCreatingSpotlightAsset: null,
                        }
                    },
                };
            }

            case spotlightAssetsActions.CREATE_SPOTLIGHT_ASSET_SUCCESS: {
                const {organizationId, spotlightAssetId} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            spotlightAssets: _.union(
                                _.get(state, `byId.${organizationId}.spotlightAssets`, []),
                                [spotlightAssetId],
                            ),
                        }
                    },
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isCreatingSpotlightAsset: false,
                            errorCreatingSpotlightAsset: null,
                        }
                    },
                };
            }

            case spotlightAssetsActions.CREATE_SPOTLIGHT_ASSET_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isCreatingSpotlightAsset: false,
                            errorCreatingSpotlightAsset: error,
                        }
                    },
                };
            }

            case scheduleActions.GET_SCHEDULES: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingSchedules: true,
                            errorFetchingSchedules: null,
                        }
                    },
                };
            }

            case scheduleActions.GET_SCHEDULES_SUCCESS: {
                const {organizationId, scheduleIds, spotlightIds} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...newState.byId,
                        [organizationId]: {
                            ...newState.byId[organizationId],
                            schedules: [...scheduleIds],
                            activeSpotlights: [...spotlightIds],
                        }
                    },
                    metadataById: {
                        ...newState.metadataById,
                        [organizationId]: {
                            ...newState.metadataById[organizationId],
                            isFetchingSchedules: false,
                            errorFetchingSchedules: null,
                        }
                    },
                };
            }

            case scheduleActions.GET_SCHEDULES_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingSchedules: false,
                            errorFetchingSchedules: error,
                        }
                    },
                };
            }

            case plutoChannelsActions.GET_PLUTO_CHANNELS: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingPlutoChannels: true,
                            errorFetchingPlutoChannels: null,
                        }
                    },
                };
            }

            case plutoChannelsActions.GET_PLUTO_CHANNELS_SUCCESS: {
                const {organizationId, plutoChannelsIds} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            plutoChannels: [...plutoChannelsIds],
                        }
                    },
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingPlutoChannels: false,
                            errorFetchingPlutoChannels: null,
                        }
                    },
                };
            }

            case plutoChannelsActions.GET_PLUTO_CHANNELS_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingPlutoChannels: false,
                            errorFetchingPlutoChannels: error,
                        }
                    },
                };
            }

            case upshowNowChannelsActions.GET_UPSHOW_NOW_CHANNELS: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingUpshowNowChannels: true,
                            errorFetchingUpshowNowChannels: null,
                        }
                    },
                };
            }

            case upshowNowChannelsActions.GET_UPSHOW_NOW_CHANNELS_SUCCESS: {
                const {organizationId, upshowNowChannelsIds} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            upshowNowChannels: [...upshowNowChannelsIds],
                        }
                    },
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingUpshowNowChannels: false,
                            errorFetchingUpshowNowChannels: null,
                        }
                    },
                };
            }

            case upshowNowChannelsActions.GET_UPSHOW_NOW_CHANNELS_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingUpshowNowChannels: false,
                            errorFetchingUpshowNowChannels: error,
                        }
                    },
                };
            }

            case jukinFeedsActions.GET_JUKIN_FEEDS: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingJukinFeeds: true,
                            errorFetchingJukinFeeds: null,
                        }
                    },
                };
            }

            case jukinFeedsActions.GET_JUKIN_FEEDS_SUCCESS: {
                const {organizationId, jukinFeedsIds} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            jukinFeeds: [...jukinFeedsIds],
                        }
                    },
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingJukinFeeds: false,
                            errorFetchingJukinFeeds: null,
                        }
                    },
                };
            }

            case jukinFeedsActions.GET_JUKIN_FEEDS_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingJukinFeeds: false,
                            errorFetchingJukinFeeds: error,
                        }
                    },
                };
            }

            case upshowNowSchedulesActions.GET_SCHEDULES: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingUpshowNowSchedules: true,
                            errorFetchingUpshowNowSchedules: null,
                        }
                    },
                };
            }

            case upshowNowSchedulesActions.GET_SCHEDULES_SUCCESS: {
                const {organizationId, scheduleIds} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...newState.byId,
                        [organizationId]: {
                            ...newState.byId[organizationId],
                            upshowNowSchedules: [...scheduleIds],
                        }
                    },
                    metadataById: {
                        ...newState.metadataById,
                        [organizationId]: {
                            ...newState.metadataById[organizationId],
                            isFetchingUpshowNowSchedules: false,
                            errorFetchingUpshowNowSchedules: null,
                        }
                    },
                };
            }

            case upshowNowSchedulesActions.GET_SCHEDULES_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingUpshowNowSchedules: false,
                            errorFetchingUpshowNowSchedules: error,
                        }
                    },
                };
            }

            case plutoSchedulesActions.GET_SCHEDULES: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingPlutoSchedules: true,
                            errorFetchingPlutoSchedules: null,
                        }
                    },
                };
            }

            case plutoSchedulesActions.GET_SCHEDULES_SUCCESS: {
                const {organizationId, scheduleIds} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...newState.byId,
                        [organizationId]: {
                            ...newState.byId[organizationId],
                            plutoSchedules: [...scheduleIds],
                        }
                    },
                    metadataById: {
                        ...newState.metadataById,
                        [organizationId]: {
                            ...newState.metadataById[organizationId],
                            isFetchingPlutoSchedules: false,
                            errorFetchingPlutoSchedules: null,
                        }
                    },
                };
            }

            case plutoSchedulesActions.GET_SCHEDULES_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingPlutoSchedules: false,
                            errorFetchingPlutoSchedules: error,
                        }
                    },
                };
            }

            case plutoSchedulesActions.CREATE_SCHEDULE_SUCCESS: {
                const {organizationId, scheduleId} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            plutoSchedules: _.union(
                                _.get(state, `byId.${organizationId}.plutoSchedules`, []),
                                [scheduleId],
                            )
                        }
                    },
                };
            }

            case plutoSchedulesActions.DELETE_SCHEDULE_SUCCESS: {
                const {organizationId, scheduleId} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            plutoSchedules: _.difference(
                                _.get(state, `byId.${organizationId}.plutoSchedules`, []),
                                [scheduleId]
                            )
                        }
                    },
                };
            }

            case jukinSchedulesActions.GET_SCHEDULES: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingJukinSchedules: true,
                            errorFetchingJukinSchedules: null,
                        }
                    },
                };
            }

            case jukinSchedulesActions.GET_SCHEDULES_SUCCESS: {
                const {organizationId, scheduleIds} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...newState.byId,
                        [organizationId]: {
                            ...newState.byId[organizationId],
                            jukinSchedules: [...scheduleIds],
                        }
                    },
                    metadataById: {
                        ...newState.metadataById,
                        [organizationId]: {
                            ...newState.metadataById[organizationId],
                            isFetchingJukinSchedules: false,
                            errorFetchingJukinSchedules: null,
                        }
                    },
                };
            }

            case jukinSchedulesActions.GET_SCHEDULES_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingJukinSchedules: false,
                            errorFetchingJukinSchedules: error,
                        }
                    },
                };
            }

            case jukinSchedulesActions.CREATE_SCHEDULE_SUCCESS: {
                const {organizationId, scheduleId} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            jukinSchedules: _.union(
                                _.get(state, `byId.${organizationId}.jukinSchedules`, []),
                                [scheduleId],
                            )
                        }
                    },
                };
            }

            case jukinSchedulesActions.DELETE_SCHEDULE_SUCCESS: {
                const {organizationId, scheduleId} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            jukinSchedules: _.difference(
                                _.get(state, `byId.${organizationId}.jukinSchedules`, []),
                                [scheduleId]
                            )
                        }
                    },
                };
            }

            case scriptSchedulesActions.GET_SCHEDULES: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingScriptSchedules: true,
                            errorFetchingScriptSchedules: null,
                        }
                    },
                };
            }

            case scriptSchedulesActions.GET_SCHEDULES_SUCCESS: {
                const {organizationId, scheduleIds} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...newState.byId,
                        [organizationId]: {
                            ...newState.byId[organizationId],
                            scriptSchedules: [...scheduleIds],
                        }
                    },
                    metadataById: {
                        ...newState.metadataById,
                        [organizationId]: {
                            ...newState.metadataById[organizationId],
                            isFetchingScriptSchedules: false,
                            errorFetchingScriptSchedules: null,
                        }
                    },
                };
            }

            case scriptSchedulesActions.GET_SCHEDULES_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingScriptSchedules: false,
                            errorFetchingScriptSchedules: error,
                        }
                    },
                };
            }

            case scriptSchedulesActions.CREATE_SCHEDULE_SUCCESS: {
                const {organizationId, scheduleId} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            scriptsSchedules: _.union(
                                _.get(state, `byId.${organizationId}.scriptsSchedules`, []),
                                [scheduleId],
                            )
                        }
                    },
                };
            }

            case scriptSchedulesActions.DELETE_SCHEDULE_SUCCESS: {
                const {organizationId, scheduleId} = action.payload;

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            scriptSchedules: _.difference(
                                _.get(state, `byId.${organizationId}.scriptSchedules`, []),
                                [scheduleId]
                            )
                        }
                    },
                };
            }

            case triviaSchedulesActions.GET_SCHEDULES: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingTriviaSchedules: true,
                            errorFetchingTriviaSchedules: null,
                        }
                    },
                };
            }

            case triviaSchedulesActions.GET_SCHEDULES_SUCCESS: {
                const {organizationId, scheduleIds} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...newState.byId,
                        [organizationId]: {
                            ...newState.byId[organizationId],
                            triviaSchedules: [...scheduleIds],
                        }
                    },
                    metadataById: {
                        ...newState.metadataById,
                        [organizationId]: {
                            ...newState.metadataById[organizationId],
                            isFetchingTriviaSchedules: false,
                            errorFetchingTriviaSchedules: null,
                        }
                    },
                };
            }

            case triviaSchedulesActions.GET_SCHEDULES_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingTriviaSchedules: false,
                            errorFetchingTriviaSchedules: error,
                        }
                    },
                };
            }

            case actions.GET_ORGANIZATION: {

                return {
                    ...state,
                    isFetchingCurrentOrganization: true
                };
            }

            case actions.GET_ORGANIZATION_SUCCESS: {
                const newState = this.shallowMergeReducer(state, action);
                return {
                    ...newState,
                    isFetchingCurrentOrganization: false
                };
            }

            case actions.GET_ORGANIZATION_FAILURE: {
                return {
                    ...state,
                    isFetchingCurrentOrganization: false
                };
            }

            // CREATE

            case uiScriptsActions.CREATE_SCRIPT: {
                const { organizationId } = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isCreatingScripts: true,
                            errorCreatingScripts: null,
                        }
                    },
                };
            }

            case uiScriptsActions.CREATE_SCRIPT_SUCCESS: {
                const { organizationId, scriptId } = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...newState.byId,
                        [organizationId]: {
                            ...newState.byId[organizationId],
                            uiScripts: (newState.byId[organizationId]?.uiScripts ?? []).concat(scriptId),
                        }
                    },
                    metadataById: {
                        ...newState.metadataById,
                        [organizationId]: {
                            ...newState.metadataById[organizationId],
                            isCreatingScripts: false,
                            errorCreatingScripts: null
                        }
                    },
                };
            }

            case uiScriptsActions.CREATE_SCRIPT_FAILURE: {
                const { organizationId, error } = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isCreatingScripts: false,
                            errorCreatingScripts: error
                        }
                    },
                };
            }

            // DUPLICATE

            case uiScriptsActions.DUPLICATE_SCRIPT: {
                const { organizationId } = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isDuplicatingScripts: true,
                            errorDuplicatingScripts: null,
                        }
                    },
                };
            }

            case uiScriptsActions.DUPLICATE_SCRIPT_SUCCESS: {
                const { organizationId, scriptId } = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...newState.byId,
                        [organizationId]: {
                            ...newState.byId[organizationId],
                            uiScripts: (newState.byId[organizationId]?.uiScripts ?? []).concat(scriptId),
                        }
                    },
                    metadataById: {
                        ...newState.metadataById,
                        [organizationId]: {
                            ...newState.metadataById[organizationId],
                            isDuplicatingScripts: false,
                            errorDuplicatingScripts: null
                        }
                    },
                };
            }

            case uiScriptsActions.DUPLICATE_SCRIPT_FAILURE: {
                const { organizationId, error } = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isDuplicatingScripts: false,
                            errorDuplicatingScripts: error
                        }
                    },
                };
            }

            // UPDATE

            case uiScriptsActions.UPDATE_SCRIPT: {
                const { organizationId } = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isUpdatingScripts: true,
                            errorUpdatingScripts: null,
                        }
                    },
                };
            }

            case uiScriptsActions.UPDATE_SCRIPT_SUCCESS: {
                const { organizationId, scriptId } = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...newState.byId,
                        [organizationId]: {
                            ...newState.byId[organizationId],
                            uiScripts: (newState.byId[organizationId]?.uiScripts ?? []).concat(scriptId),
                        }
                    },
                    metadataById: {
                        ...newState.metadataById,
                        [organizationId]: {
                            ...newState.metadataById[organizationId],
                            isUpdatingScripts: false,
                            errorUpdatingScripts: null
                        }
                    },
                };
            }

            case uiScriptsActions.UPDATE_SCRIPT_FAILURE: {
                const { organizationId, error } = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isUpdatingScripts: false,
                            errorUpdatingScripts: error
                        }
                    },
                };
            }

            // DELETE

            case uiScriptsActions.DELETE_SCRIPT: {
                const { organizationId } = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isDeletingScripts: true,
                            errorDeletingScripts: null,
                        }
                    },
                };
            }

            case uiScriptsActions.DELETE_SCRIPT_SUCCESS: {
                const { organizationId } = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    metadataById: {
                        ...newState.metadataById,
                        [organizationId]: {
                            ...newState.metadataById[organizationId],
                            isDeletingScripts: false,
                            errorDeletingScripts: null
                        }
                    },
                };
            }

            case uiScriptsActions.DELETE_SCRIPT_FAILURE: {
                const { organizationId, error } = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isDeletingScripts: false,
                            errorDeletingScripts: error
                        }
                    },
                };
            }

            // UI SCRIPTS

            case uiScriptsActions.GET_UI_SCRIPTS: {
                const {organizationId} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingUiScripts: true,
                            errorFetchingUiScripts: null,
                        }
                    },
                };
            }

            case uiScriptsActions.GET_UI_SCRIPTS_SUCCESS: {
                const {organizationId, uiScripts} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...newState.byId,
                        [organizationId]: {
                            ...newState.byId[organizationId],
                            uiScripts: [...uiScripts],
                        }
                    },
                    metadataById: {
                        ...newState.metadataById,
                        [organizationId]: {
                            ...newState.metadataById[organizationId],
                            isFetchingUiScripts: false,
                            errorFetchingUiScripts: null
                        }
                    },
                };
            }

            case uiScriptsActions.GET_UI_SCRIPTS_FAILURE: {
                const {organizationId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                            isFetchingUiScripts: false,
                            errorFetchingUiScripts: error
                        }
                    },
                };
            }


            //ACCOUNTS SCRIPT

            case uiScriptsActions.GET_ACCOUNTS_SCRIPT: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                        }
                    },
                };
            }


            case uiScriptsActions.GET_ACCOUNTS_SCRIPT_SUCCESS: {
                const {organizationId, accountsScript} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            accountsScript: [...accountsScript],
                        }
                    },
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                        }
                    },
                };
            }

            case uiScriptsActions.GET_ACCOUNTS_SCRIPT_FAILURE: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                        }
                    },
                };
            }

            //DEVICES SCRIPT
            case uiScriptsActions.GET_DEVICES_SCRIPT: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                        }
                    },
                };
            }

            case uiScriptsActions.GET_DEVICES_SCRIPT_SUCCESS: {
                const {organizationId, devicesScript} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            devicesScript: [...devicesScript],
                        }
                    },
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                        }
                    },
                };
            }

            case uiScriptsActions.GET_DEVICES_SCRIPT_FAILURE: {
                const {organizationId} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                        }
                    },
                };
            }

            //DEFAULT ORG SCRIPT
            case uiScriptsActions.GET_DEFAULT_ORG_SCRIPT: {
                const {organizationId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                        }
                    },
                };
            }

            case uiScriptsActions.GET_DEFAULT_ORG_SCRIPT_SUCCESS: {
                const {organizationId, defaultOrgScript} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    byId: {
                        ...state.byId,
                        [organizationId]: {
                            ...state.byId[organizationId],
                            defaultOrgScript,
                        }
                    },
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                        }
                    },
                };
            }

            case uiScriptsActions.GET_DEFAULT_ORG_SCRIPT_FAILURE: {
                const {organizationId} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [organizationId]: {
                            ...state.metadataById[organizationId],
                        }
                    },
                };
            }

            default: {
                return super.reducer(state, action);
            }
        }
    }

    errorFetchingRootOrganizations(state) {
        return this.getState(state).errorFetchingRootOrganizations;
    }

    isFetchingRootOrganizations(state) {
        return this.getState(state).isFetchingRootOrganizations;
    }

    hasOrganizations(state) {
        const rootOrganizationsIds = this.getRootOrganizationsIds(state);
        return rootOrganizationsIds.length > 0;
    }

    isRoot(state, id) {
        let rootIds = this.getRootOrganizationsIds(state);

        return rootIds.includes(id);
    }

    isAbsoluteRoot(state, id) {
        const organization = this.findById(state, id);
        return  !!organization && !!organization.root;
    }

    isLeaf(state, id) {
        const org = this.findById(state, id) || {};
        return !!org.leaf;
    }

    getOrganizationSetting(state, id, key) {
        const org = this.findById(state, id) || {};
        return org.settings.find(setting => setting.key === key)?.value
    }

    organizationPathIsValid(state, path) {
        const isValid = Array.isArray(path) && path.every(id => Number.isInteger(id));

        const firstIsRoot = (path) => this.isRoot(state, path[0]);

        const pathExists = (path) => {
            const [nextId, ...trailingPath] = path;
            const org = this.findById(state, nextId);

            if (!org) {
                return false;
            }

            if (trailingPath.length > 0) {
                return pathExists(trailingPath);
            }

            return true;
        };

        return isValid && firstIsRoot(path) && pathExists(path);
    }

    isSwitchingOrganization(state) {
        return this.getState(state).isSwitchingOrganization;
    }

    errorSwitchingOrganization(state) {
        return this.getState(state).errorSwitchingOrganization;
    }

    getCurrentOrganizationPathIds(state) {
        return this.getState(state).currentOrganizationPath;
    }

    getCurrentOrganizationPath(state, denormalized = false) {
        const ids = this.getCurrentOrganizationPathIds(state);
        return this.findByIds(state, ids, denormalized);
    }

    getCurrentOrganizationId(state) {
        const [currentOrgId] = this.getCurrentOrganizationPathIds(state).slice(-1);
        return currentOrgId;
    }

    getCurrentOrganization(state, denormalized = false) {
        const id = this.getCurrentOrganizationId(state);
        return this.findById(state, id, denormalized);
    }

    getCurrentOrganizationBrandkitMap(state) {
        const organization = this.getCurrentOrganization(state, false);

        return organization.brandKit;
    }

    getCurrentOrganizationName(state) {
        const organization = this.getCurrentOrganization(state);
        return organization ? organization.name : null;
    }

    getCurrentOrganizationExperience(state) {
        const organization = this.getCurrentOrganization(state);
        return organization?.experience;
    }

    getFeatures(state) {
        const organization = this.getCurrentOrganization(state);
        let features = [];

        if(organization && organization.features) {
            features = organization.features.filter(feature => feature.enabled).map(feature => feature.feature);
        }

        return features;
    }

    getPermissionAccesor(state) {
        const organization = this.getCurrentOrganization(state);
        const permissions = organization?.permissions ?? [];

        return (permission = 'catch_all', strict_checking = false) => {
            if (permissions.length === 0) return false;
            if (permissions.includes('owner') && !strict_checking) return true;

            return permissions.includes(permission);
        }
    }

    getOrganizationVertical(state) {
        const organization = this.getCurrentOrganization(state);
        return organization?.vertical?.key ?? 'gen';
    }

    hasTriviaGameId(state) {
        return !!_.get(this.getCurrentOrganization(state), 'hasTriviaGameId', false);
    }

    orgFeaturesLoading(state){
        return !_.get(this.getCurrentOrganization(state),"features",false);
    }

    getFeatureAccesor(state) {
        const features = this.getFeatures(state);

        return buildFeatureAccesor(features);
    }

    getRootOrganizationsIds(state) {
        return this.getState(state).rootOrganizations;
    }

    getFirstRootOrganizationId(state) {
        const rootOrgs = this.getRootOrganizationsIds(state);
        return Array.isArray(rootOrgs) ? rootOrgs[0] : null;
    }

    getRootOrganizations(state, denormalized = false) {
        const ids = this.getRootOrganizationsIds(state);
        return this.findByIds(state, ids, denormalized);
    }

    isFetchingContent(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingContent;
    }

    getContentIds(state, id) {
        const organization = this.findById(state, id);
        return organization && Array.isArray(organization.content) ? organization.content : [];
    }

    isFetchingSpotlights(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingSpotlights;
    }

    isCreatingSpotlight(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isCreatingSpotlight;
    }

    getSpotlightsIds(state, id) {
        const organization = this.findById(state, id);
        return organization && Array.isArray(organization.spotlights) ? organization.spotlights : [];
    }

    isFetchingUiScripts(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingUiScripts;
    }

    isFetchingSpotlightBackgrounds(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingSpotlightBackgrounds;
    }

    getSpotlightBackgroundsIds(state, id) {
        const organization = this.findById(state, id);
        return organization && Array.isArray(organization.spotlightBackgrounds) ? organization.spotlightBackgrounds : [];
    }

    isFetchingSpotlightAssets(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingSpotlightAssets;
    }

    isCreatingSpotlightAsset(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isCreatingSpotlightAsset;
    }

    getSpotlightAssetsIds(state, id) {
        const organization = this.findById(state, id);
        return organization && Array.isArray(organization.spotlightAssets) ? organization.spotlightAssets : [];
    }

    getActiveSpotlightsIds(state, id) {
        const organization = this.findById(state, id);
        return organization && Array.isArray(organization.activeSpotlights) ? organization.activeSpotlights : [];
    }

    isFetchingSchedules(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingSchedules;
    }

    getSchedulesIds(state, id) {
        const organization = this.findById(state, id);
        return organization && Array.isArray(organization.schedules) ? organization.schedules : [];
    }

    isFetchingUpshowNowChannels(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingUpshowNowChannels;
    }

    isFetchingJukinFeeds(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingJukinFeeds;
    }

    getUpshowNowChannelsIds(state, id) {
        const organization = this.findById(state, id);
        return organization && Array.isArray(organization.upshowNowChannels) ? organization.upshowNowChannels : [];
    }

    getJukinFeedsIds(state, id) {
        const organization = this.findById(state, id);
        return organization && Array.isArray(organization.jukinFeeds) ? organization.jukinFeeds : [];
    }

    isFetchingUpshowNowSchedules(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingUpshowNowSchedules;
    }

    isFetchingPlutoChannels(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingPlutoChannels;
    }

    isFetchingPlutoSchedules(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingPlutoSchedules;
    }

    getUpshowNowSchedulesIds(state, id) {
        const organization = this.findById(state, id);
        return organization && Array.isArray(organization.upshowNowSchedules) ? organization.upshowNowSchedules : [];
    }

    getPlutoChannelsIds(state, id) {
        const organization = this.findById(state, id);
        return organization && Array.isArray(organization.plutoChannels) ? organization.plutoChannels : [];
    }

    getPlutoSchedulesIds(state, id) {
        const organization = this.findById(state, id);
        return organization && Array.isArray(organization.plutoSchedules) ? organization.plutoSchedules : [];
    }

    isFetchingJukinSchedules(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingJukinSchedules;
    }

    getJukinSchedulesIds(state, id) {
        const organization = this.findById(state, id);
        return organization && Array.isArray(organization.jukinSchedules) ? organization.jukinSchedules : [];
    }

    isFetchingScriptSchedules(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingScriptSchedules;
    }

    getScriptSchedulesIds(state, id) {
        const organization = this.findById(state, id);
        return organization && Array.isArray(organization.scriptSchedules) ? organization.scriptSchedules : [];
    }

    isFetchingTriviaSchedules(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingTriviaSchedules;
    }

    getTriviaSchedulesIds(state, id) {
        const organization = this.findById(state, id);
        return organization && Array.isArray(organization.triviaSchedules) ? organization.triviaSchedules : [];
    }

    isFetchingMixesSchedules(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isFetchingMixesSchedules;
    }

    isDeletingScripts(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isDeletingScripts;
    }

    isCreatingScripts(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isCreatingScripts;
    }

    isDuplicatingScripts(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isDuplicatingScripts;
    }

    isUpdatingScripts(state, id) {
        const metadata = this.getMetadata(state, id);
        return !!metadata.isUpdatingScripts;
    }

    errorDuplicatingScripts(state, id) {
        const metadata = this.getMetadata(state, id);
        return metadata.errorDuplicatingScripts;
    }

    errorCreatingScripts(state, id) {
        const metadata = this.getMetadata(state, id);
        return metadata.errorCreatingScripts;
    }

    errorUpdatingScripts(state, id) {
        const metadata = this.getMetadata(state, id);
        return metadata.errorUpdatingScripts;
    }

    errorDeletingScripts(state, id) {
        const metadata = this.getMetadata(state, id);
        return metadata.errorDeletingScripts;
    }

    getCurrentOrganizationUrl(state) {
        const currentOrgPath = this.getCurrentOrganizationPathIds(state);
        return buildUrl( 'organizations', currentOrgPath);
    }

    getCurrentOrganizationRelativeUrl(state, path) {
        return this.getCurrentOrganizationUrl(state) + path;
    }

    getRedirectToRelativeUrl(state) {
        return this.getState(state).redirectToRelativeUrl;
    }

    getRedirectToAbsoluteUrl(state) {
        const relativePath = this.getState(state).redirectToRelativeUrl;
        return relativePath ? this.getCurrentOrganizationRelativeUrl(state, relativePath) : null;
    }

    getTimezones(state) {
        const timezones = this.getState(state).timezones;
        if (timezones && timezones.length) {
            return timezones;
        } else {
            return defaultTimezoneList;
        }
    }

    getTimezoneNames(state) {
        return this.getTimezones(state).map(tz => tz.timezone);
    }

    getDefaultTimezoneName(state) {
        return this.getTimezoneNames(state)[0];
    }

    isFetchingCurrentOrganization(state) {
        return this.getState(state).isFetchingCurrentOrganization;
    }

    getAccountsScriptById(state, id) {
        const organization = this.findById(state, id);
        return organization?.accountsScript ?? [];
    }

    getUiScriptsById(state, id) {
        const organization = this.findById(state, id);
        return organization?.uiScripts ?? [];
    }

    getDevicesScriptById(state, id) {
        const organization = this.findById(state, id);
        return organization?.devicesScript ?? [];
    }

    getDefaultOrgScriptById(state, id) {
        const organization = this.findById(state, id);
        return organization?.defaultOrgScript;
    }

    getCurrentTimezoneName() {
        return 'America/Chicago';
    }
}

export default new Organization();
